import { createSelector } from 'reselect';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import { StorageKeys } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.layouts;

export const isMapConfigFetchedSelector = createSelector(rootSelector, layouts => layouts.isMapConfigFetched);

export const paginationSelectorFactory = (type: Type.PaginationType) =>
  createSelector(rootSelector, (layouts: Layouts.Root): Layouts.Pagination => layouts.pagination[type]);

export const modalConfirmationMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['modalConfirmationMeta'] => layouts.modalConfirmationMeta
);

export const isSidebarOpenSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['isSidebarOpen'] => layouts.isSidebarOpen
);

export const formsModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['formsModalId'] => layouts.formsModalId
);

export const manualsModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['manualsModalId'] => layouts.manualsModalId
);

export const taskModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['taskModalMeta'] => layouts.taskModalMeta
);

const toastsSelector = createSelector(rootSelector, (layouts: Layouts.Root): Layouts.Root['toasts'] => layouts.toasts);

export const globalToastsSelector = createSelector(
  toastsSelector,
  (toasts: Layouts.Root['toasts']): Layouts.Root['toasts'] => toasts.filter(toast => !toast.isErrorOnlyForModal)
);

export const onlyModalToastsSelector = createSelector(
  toastsSelector,
  (toasts: Layouts.Root['toasts']): Layouts.Root['toasts'] => toasts.filter(toast => toast.isErrorOnlyForModal)
);

export const assetModelModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['assetModelsModalId'] => layouts.assetModelsModalId
);

export const employeeModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['employeeModalId'] => layouts.employeeModalId
);

export const assetSubComponentModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['assetSubComponentModalId'] => layouts.assetSubComponentModalId
);

export const repeatedTasksSectionModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['repeatedTasksSectionModalId'] => layouts.repeatedTasksSectionModalId
);

export const repeatedTaskModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['repeatedTaskModalId'] => layouts.repeatedTaskModalId
);

export const profileModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['profileModalMeta'] => layouts.profileModalMeta
);

export const customersRightsProfileModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['customersRightsProfileModalId'] => layouts.customersRightsProfileModalId
);

export const tasksChecklistsModalIdSelector = createSelector(rootSelector, layouts => layouts.tasksChecklistsModalId);

export const tasksMaterialsModalIdSelector = createSelector(rootSelector, layouts => layouts.tasksMaterialsModalId);

export const historyChecklistsModalIdSelector = createSelector(
  rootSelector,
  layouts => layouts.historyChecklistsModalId
);

export const maintenancePlansModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['maintenancePlansModalId'] => layouts.maintenancePlansModalId
);

export const metersPopulationModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['metersPopulationModalId'] => layouts.metersPopulationModalId
);

export const permissionsGroupsModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['permissionsGroupsModalId'] => layouts.permissionsGroupsModalId
);

export const permissionsUsersModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['permissionsUsersModalId'] => layouts.permissionsUsersModalId
);

export const featureTogglesSelector = createSelector(
  [rootSelector, appCurrentUserPermissionsSelector],
  (layouts: Layouts.Root, appCurrentUserPermissions: Permissions.Permissions): Layouts.Root['featureToggles'] =>
    appCurrentUserPermissions.isFeatureToggleAvailable ? layouts.featureToggles : ({} as Layouts.Root['featureToggles'])
);

export const portfolioIdSelector = createSelector(
  [rootSelector],
  (layouts: Layouts.Root): Layouts.Root['portfolioId'] => layouts.portfolioId
);

export const scenarioIdHashSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['scenarioIdHash'] => layouts.scenarioIdHash
);

export const scenarioIdSelector = createSelector(
  [scenarioIdHashSelector, portfolioIdSelector],
  (hash: Layouts.Root['scenarioIdHash'], portfolioId: Layouts.Root['portfolioId']): Layouts.ScenarioId =>
    (portfolioId && hash?.[portfolioId]) || null
);

export const simulationIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['simulationId'] => layouts.simulationId
);

export const investmentScenarioIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['investmentScenarioId'] => layouts.investmentScenarioId
);

export const DERModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['DERModalId'] => layouts.DERModalId
);

export const overviewScenarioModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['overviewScenarioModalMeta'] => layouts.overviewScenarioModalMeta
);

export const newLoadDERsCategoriesModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['newLoadDERsCategoriesModalId'] => layouts.newLoadDERsCategoriesModalId
);

export const newLoadDERProfileModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['newLoadDERProfileModalId'] => layouts.newLoadDERProfileModalId
);

export const newLoadEditIndividualCustomerModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['newLoadEditIndividualCustomerModalId'] =>
    layouts.newLoadEditIndividualCustomerModalId
);

export const selectedMapThemeSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['selectedMapTheme'] => layouts.selectedMapTheme
);

export const muteErrorToastActionSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['muteErrorToastAction'] => layouts.muteErrorToastAction
);

export const permissionsTenantsModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['permissionsTenantsModalId'] => layouts.permissionsTenantsModalId
);

export const permissionsFeaturesModalIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['permissionsFeaturesModalId'] => layouts.permissionsFeaturesModalId
);

export const singleDiagramEnabledSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['singleDiagramEnabled'] => layouts.singleDiagramEnabled
);

export const mapPanelEnabledSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['mapPanelEnabled'] => layouts.mapPanelEnabled
);

export const investmentScenarioModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['investmentScenarioModalMeta'] => layouts.investmentScenarioModalMeta
);

export const investmentComponentPriceAssetModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['investmentComponentPriceAssetModalMeta'] =>
    layouts.investmentComponentPriceAssetModalMeta
);

export const componentPriceScenarioModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['componentPriceScenarioModalMeta'] => layouts.componentPriceScenarioModalMeta
);

export const singleDiagramFilterPrimarySubstationsIdSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['singleDiagramFilterPrimarySubstationsId'] =>
    layouts.singleDiagramFilterPrimarySubstationsId
);

export const singleDiagramFilterVoltagesIdsSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['singleDiagramFilterVoltagesIds'] => layouts.singleDiagramFilterVoltagesIds
);

export const singleDiagramTypeSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['singleDiagramType'] => layouts.singleDiagramType
);

export const singleDiagramServiceAssetsSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['singleDiagramServiceAssets'] => layouts.singleDiagramServiceAssets
);

export const selectedChartYearSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['selectedChartYear'] => layouts.selectedChartYear
);

export const selectedChartFlexSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['selectedChartFlex'] => layouts.selectedChartFlex
);

export const selectedChartAbsoluteSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['selectedChartAbsolute'] => layouts.selectedChartAbsolute
);

export const investmentWorkPriceModalMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['investmentWorkPriceModalMeta'] => layouts.investmentWorkPriceModalMeta
);

export const createUserOneTimePasswordSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['createUserOneTimePassword'] => layouts.createUserOneTimePassword
);

export const isCO2eCumulativeOverviewSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['isCO2eCumulativeOverview'] => layouts.isCO2eCumulativeOverview
);

export const CO2eScenariosYearSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['CO2eScenariosYear'] => layouts.CO2eScenariosYear
);

export const maintenanceAverageTimeSpentByAssetCategorySelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['maintenanceAverageTimeSpentByAssetCategory'] =>
    layouts.maintenanceAverageTimeSpentByAssetCategory
);

export const maintenanceTimeByActionTypeAssetCategoriesSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['maintenanceTimeByActionTypeAssetCategories'] =>
    layouts.maintenanceTimeByActionTypeAssetCategories
);

export const tableSettingsModalSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['tableSettingsModal'] => layouts.tableSettingsModal
);

export const mapDrawAssetFeatureMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['mapDrawAssetFeatureMeta'] => layouts.mapDrawAssetFeatureMeta
);

export const releaseNotificationModalVersionSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['releaseNotificationModalVersion'] => layouts.releaseNotificationModalVersion
);

export const isExtendedGridEnabledSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['isExtendedGridEnabled'] => layouts.isExtendedGridEnabled
);

export const isMapConnectionsEnabledSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['isMapConnectionsEnabled'] => layouts.isMapConnectionsEnabled
);

export const mapAddConnectionMetaSelector = createSelector(
  rootSelector,
  (layouts: Layouts.Root): Layouts.Root['mapAddConnectionMeta'] => layouts.mapAddConnectionMeta
);

// Factory to create a property selector from Layouts.Root
export const layoutPropertySelectorFactory = <K extends keyof Layouts.Root>(prop: K) =>
  createSelector(rootSelector, (layouts: Layouts.Root): Layouts.Root[K] => layouts[prop]);

export const selectedCurrencySelector = createSelector(
  [rootSelector, featureTogglesSelector],
  (layouts, featureToggles): Layouts.Root['selectedCurrency'] | null =>
    featureToggles[StorageKeys.FEATURE_CURRENCIES] ? layouts.selectedCurrency : null
);
