import React from 'react';
import { Map } from 'components';
import { useSelector } from 'react-redux';
import MapContextProvider from 'context/map';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import MapPanels from 'components/Panels/MapPanels';
import SingleLineDiagram from 'components/SingleLineDiagram';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { PermissionsTypes } from 'constants/index';

const PageMap: React.FC = () => {
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isMapEnabled}>
      <RequiredScenarioCheck>
        <MapContextProvider>
          <MapPanels>
            {singleDiagramEnabled && <SingleLineDiagram />}
            {/* // FixMe. MapPanel must work without map component */}
            <div style={{ display: singleDiagramEnabled ? 'none' : 'initial' }}>
              <Map />
            </div>
          </MapPanels>
        </MapContextProvider>
      </RequiredScenarioCheck>
    </PagePermissionsProvider>
  );
};

export default PageMap;
